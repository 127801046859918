.delete-messages {
    .customdaterangelabel {
        .fui-Label {
            padding-right: 0;
        }
        .fui-InfoLabel {
            position: relative;
            svg{
                height: 19px;
                width: 19px;
                margin-top: 30%;
            }
        }
    }    
}